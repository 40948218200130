<template>
    <div class="myhistory">
        <p class="title">
            <i v-if="data.source_key" :class="'iconfont icon-' + data.source_key" :style="'color:' + data.icon_color"></i>
            <span>{{ data.name }}</span>
            <span class="title-sm">（历史数据）</span>
        </p>

        <div class="history-box" v-for="(item, k) in list" :key="k">
            <p>{{item.day}}</p>
            <div class="row-box">
                <a class="history-item" v-for="(item, i) in item.data" :key="'s' + i" :href="item.link" target="_blank" :title="item.title" @click="record(item.id)">
                    <span>{{i + 1}}. </span>
                    <span>{{item.title}}</span>
                    <span>{{item.extra}}</span>
                </a>
            </div>
        </div>

        <p v-if="list.length === 0 && !page" class="history-none">无历史数据</p>

        <div class="btn-box">
            <button v-if="page && page <= 7 && data.id" class="btn btn-blue-full" @click="getList">加载更多...</button>
        </div>
    </div>
</template>

<script>
let dayArr = ['今天', '昨天', '前天']
export default {
    name: 'myhostory',
    data() {
        return {
            id: 1,
            data: {

            },
            page: 1,
            list: [],
        }
    },
    methods: {
        // 获取订阅列表
        getList() {
            let that = this
            if (that.page) {
                that.$({
                    url: `/hot/listHistory?id=${that.id}&day=${that.page}`,
                    success(res) {
                        if (res.data.data.length > 0) {
                            that.list.push({
                                day: that.page <= 2 ? dayArr[that.page] : res.data.day,
                                data: res.data.data
                            })
                            that.page++
                        }
                        else {
                            if (that.page === 1) {
                                that.$toast('无历史数据')
                            }
                            else {
                                that.$toast('无更多可加载')
                            }
                            that.page = false
                        }
                        that.data = res.data.source
                    },
                    error() {
                        that.$toast('获取历史数据失败', 'error')
                    }
                })
            }
        },
        // 点击热榜埋点
        record(id) {
            this.$({
                url: '/record',
                data: {
                    type: 1,
                    content: id
                },
                type: 'POST',
                loading: false
            })
        },
    },
    created() {
        this.id = this.$route.query.id
        if (this.id && Number(this.id)) this.getList()
        else {
            this.$toast('获取平台失败', 'error')
        }
    },
}
</script>

<style scoped lang="less">
.myhistory {
    min-height: calc(100% - 180px);
    max-width: 1024px;
    margin: 0 auto;
    padding: 30px 20px 20px 20px;
}

.mobile {
    display: none;
}

.title {
    padding-bottom: 20px;
    font-size: 1.3rem;
    font-weight: bolder;

    > .iconfont {
        font-size: 2rem;
        vertical-align: sub;
        margin-right: 5px;
    }
}

.title-sm {
    font-size: 0.8rem;
}

.history-box {
    padding-bottom: 10px;

    > p {
        padding: 10px 0;
        margin-bottom: 10px;
        font-size: 0.9rem;
        font-weight: bolder;
    }

    > .row-box {
        background-color: #fff;
        border-radius: 20px;
        padding: 30px 20px;
        margin-bottom: 20px;
        line-height: 1.1rem;

        > .history-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 8px 10px;
            font-size: 14px;
            border-radius: 4px;

            > span:nth-child(2) {
                margin: 0 auto 0 10px;
            }

            &:nth-of-type(1) > span:nth-of-type(1) {
                color: #cc3939;
            }

            &:nth-of-type(2) > span:nth-of-type(1) {
                color: #de6b30;
            }

            &:nth-of-type(3) > span:nth-of-type(1) {
                color: #cc984f;
            }
        }
    }
}

.history-none {
    padding: 40px 0;
    text-align: center;
}

.btn-box {
    text-align: center;
    padding: 20px 0 40px 0;

    > .btn {
        padding: 8px 40px;
    }
}

// 移动端兼容
@media screen and (max-width: 768px) {
}
</style>